export default function contarRegistrosPorAsesor(registros) {
    // Definir un objeto para almacenar el conteo de registros por asesor
    const conteoRegistrosPorAsesor = {};

    // Recorrer cada registro
    registros.forEach(registro => {
        // Obtener el nombre completo del asesor del registro
        const nombreCompleto = registro.nombre_asesor;

        // Obtener el primer nombre del nombre completo
        const primerNombre = nombreCompleto.split(' ')[0];

        // Si el asesor ya está en el conteo, aumentar su conteo en 1
        // Si no, inicializar su conteo en 1
        if (conteoRegistrosPorAsesor[registro.uuid_asesor]) {
            conteoRegistrosPorAsesor[registro.uuid_asesor]++;
        } else {
            conteoRegistrosPorAsesor[registro.uuid_asesor] = 1;
        }
    });

    // Convertir el objeto de conteo a un array de objetos con los datos de cada asesor
    const resultado = Object.keys(conteoRegistrosPorAsesor).map(uuid_asesor => ({
        name: registros.find(registro => registro.uuid_asesor === uuid_asesor).nombre_asesor,
        short_name: registros.find(registro => registro.uuid_asesor === uuid_asesor).nombre_asesor.split(' ')[0],
        citas: conteoRegistrosPorAsesor[uuid_asesor],
        uuid_asesor: uuid_asesor
    }));

    return resultado;
}