import React, { useEffect, useState } from "react";

import "./AsesoresTable.scss";

const AsesoresTable = ({
  data: AsesoresList,
  registros,
  onAsesorClick,
  small = false,
  asesorData
}) => {
  const [cerradosOrdenados, setCerradosOrdenados] = useState([]);

  const handleRowClick = (asesor) => {
    onAsesorClick(asesor);
  };

  useEffect(() => {
    const cerrados = [];

    AsesoresList.forEach((asesor) => {
      // todo registro que sea del asesor y tenga un 100% de cierre
      const asesorRegistros = registros.filter(
        (registro) => registro.uuid_asesor == asesor.uuid
      );
      let cerradosDelAsesor = 0;
      asesorRegistros.forEach((registro) => {
        if (registro.porcentaje_de_cierre == "100%") {
          cerradosDelAsesor++;
        }
      });

      cerrados.push({
        uuid: asesor.uuid,
        cerrados: cerradosDelAsesor,
      });
    });

    if(asesorData?.asesorDB == "asesores") {
      //si es asesor, solo puede analizarse a si mismo
      setCerradosOrdenados([cerrados.find(asesor => asesor?.uuid == asesorData?.uuid)])

    } else {
      // si es gerente->puede analizar todos los asesores
      setCerradosOrdenados(cerrados.sort((a, b) => b.cerrados - a.cerrados))
    }

  }, []);

  
  return (
    <div className={`asesores-table-container ${small ? "small" : ""}`}>
      <table className="asesores-table">
        <thead>
          <tr>
            <th>CUA</th>
            <th>Citas Cerradas</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Celular</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {cerradosOrdenados.map((_asesor) => {
            // mapeo del array ordenado y con uuids consulto sus demas datos
            const asesor = AsesoresList?.find(asesor => asesor.uuid == _asesor.uuid)
            
            return (
              <tr key={asesor.uuid} onClick={() => handleRowClick(asesor)}>
                <td>{asesor?.cua ?? " "}</td>
                <td>{_asesor?.cerrados}</td>
                <td>{asesor.nombre}</td>
                <td>{asesor.correo}</td>
                <td>{asesor.telefono}</td>
                <td>{asesor?.address_estado ?? " "}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AsesoresTable;
