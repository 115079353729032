import React from 'react';

//utils
import convertirADinero from '../../Utils/convertirADinero';
import extraerPrimerNombre from '../../Utils/extraerPrimerNobre';

//styles
import "./RegistrosTable.scss"

const RegistrosTable = ({ data, small=false }) => {
  return (
    <div className={`registros-table-container ${small ? "small" : ""}`}>
      <table className="registros-table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Cliente</th>
            <th>Teléfono</th>
            <th>Correo</th>
            <th>Edad</th>
            <th>Nacimiento</th>
            <th>Producto</th>
            <th>Vincular con</th>
            <th>Porcentaje</th>
            <th>Comentarios</th>
          </tr>
        </thead>
        <tbody>
          {data.map((registro, index) => (
            <tr key={index}>
              <td>{registro?.fecha}</td>
              <td>{registro?.nombre_cliente ? registro?.nombre_cliente : "Sin datos"}</td>
              <td>{registro?.telefono_cliente}</td>
              <td>{registro?.correo_cliente}</td>
              <td>{registro?.edad_cliente}</td>
              <td>{registro?.fecha_nacimiento}</td>
              <td>{registro?.producto}</td>
              <td>{registro?.vincular_con}</td>
              <td>{registro?.porcentaje_de_cierre ? registro.porcentaje_de_cierre : "Sin datos"}</td>
              <td>{registro?.comentarios ? registro.comentarios : "Sin datos"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RegistrosTable;
