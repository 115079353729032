import { useEffect, useState } from "react"


// import
import Swal from 'sweetalert2';


// imports from mui x charts
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from "@mui/x-charts";

// Componentes
import AsesoresTable from "../../Components/AsesoresTable/AsesoresTable";
import RegistrosTable from "../../Components/RegistrosTable/RegistrosTable";
import SelectQuarter from "../../Components/SelectQuarter/SelectQuarter";

// Utils
import OrdenarArrayPorAtributo from "../../Utils/Sort";
import ContarProductos from "../../Utils/ContarProductos";
import ContarPorcentajeDeCierres from "../../Utils/ContarPorcentajeDeCierres";
import ContarRegistrosPorAsesor from "../../Utils/ContarRegistrosPorAsesor";
import filtrarRegistrosPorAsesor from "../../Utils/FiltrarRegistrosPorAsesor";
import ObtenerTrimestreActual from "../../Utils/ObtenerTrimestreActual";
import ContarRegistrosPorSemana from "../../Utils/ContarRegistrosPorSemana";
import FiltrarRegistrosPorTrimestre from "../../Utils/FiltrarRegistrosPorTrimestre";


// styles
import "./Dashboard.scss"


export default function({asesorData}) {
  const chartsPalette = [
    "#e89729", // Color Principal
    "#945a23", // Contraste Oscuro
    "#f0c18e", // Contraste Claro
    "#805016",  // Contraste Más Oscuro
    "#d18d35", // Tono Más Claro
    "#e4a14f", // Otro Tono Más Claro
    "#a96519", // Tono Más Oscuro
    "#935412", // Otro Tono Más Oscuro
    "#bc853c", // Tono Similar
    "#e4b072", // Tono Similar Claro
];

  
  //Conexión con registros DB
  const SteinStore = require("stein-js-client");
  const APIRegistrosDB = new SteinStore(process.env.REACT_APP_REGISTROS_API);
  const APIAsesoresDB = new SteinStore(process.env.REACT_APP_MIEMBROS_API);


  //Variables para ibases de datos completas y ya filtradas para cada grafica
  const [RegistrosDB, setRegistrosDB] = useState(null)
  const [RegistrosTrimestrales, setRegistrosTrimestrales] = useState([])
  const [AsesoresDB, setAsesoresDB] = useState(null)
  const [ConteoDeRegistrosDB, setConteoDeRegistrosDB] = useState(null)
  const [ConteoDeRegistrosPorSemanaDB, setConteoDeRegistrosPorSemanaDB] = useState(null)
  const [ConteoDeProductosDB, setConteoDeProductosDB] = useState(null)
  const [ConteoDePorcentajesDB, setConteoDePorcentajesDB] = useState(null)
  
  
  
  //Estados de funcionamiento para analizar un asesor individualmente
  const [analizarAsesor, setAnalizarAsesor] = useState(null)
  const [analizarAsesorRegistros, setAnalizarAsesorRegistros] = useState([])
  const [analizarAsesorRegistrosPorSemana, setAnalizarAsesorRegistrosPorSemana] = useState([])
  const [analizarAsesorProductos, setAnalizarAsesorProductos] = useState([])
  const [analizarAsesorPorcentajes, setAnalizarAsesorPorcentajes] = useState([])



  
  
  //filtro 

  const [fecha_a_medir, setFecha_a_medir] = useState(ObtenerTrimestreActual())
  // const [fecha_a_medir, setFecha_a_medir] = useState('3Q2023')
  
  
  // Funcionalidad del dashboard, activamos pestaña de carga
  const [loading, setLoading] = useState(true)
  const [initRender, setInitRender] = useState(1)


  
  // estas son las variables de la primer grafica
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedAsesores, setPaginatedAsesores] = useState([]);
  const [paginatedRegistros, setPaginatedRegistros] = useState([]);
  const itemsPerPage = 10;





  
  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  
  
  
  


  const TopChartSetting = {
    xAxis: [
      {
        // label: 'Prospectos',
      },
    ],
    width: 600,
    height: 300,
    margin: {left: 70}
  };
  const MainChartStyles = {
    height: 650,
    margin: { left: 160, top: 50, bottom: 50, right: 10 },
    colors: chartsPalette
  };



     


  // para el paginador de la primer grafica
  useEffect(() => {
    if (ConteoDeRegistrosDB) {
      const totalItems = ConteoDeRegistrosDB.length;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      setCurrentPage(1); // Reset to first page when data changes
    }
  }, [ConteoDeRegistrosDB]);

  useEffect(() => {
    if (ConteoDeRegistrosDB) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setPaginatedRegistros(ConteoDeRegistrosDB.slice(startIndex, endIndex));
    }
  }, [ConteoDeRegistrosDB, currentPage]);

  


  // Actualizaremos la data de las graficas casda que cambiemos la fecha a medir
  useEffect(() => {
    setInitRender(2)
  }, [fecha_a_medir])





  //if it is the fitrst render, now get the api for DBs
  useEffect(() => {
    
    if(initRender === 2) { //se consume la api por primera vez

      APIRegistrosDB
          .read("Registros", {authentication: {
            username: process.env.REACT_APP_REGISTROS_USERNAME,
            password: process.env.REACT_APP_REGISTROS_PASSWORD,
          }})
          .then(data => {
            let registrosDB = data;
            setRegistrosDB(data)

            let registrosDelTrimestre = FiltrarRegistrosPorTrimestre(registrosDB, fecha_a_medir);
            setRegistrosTrimestrales(registrosDelTrimestre)
            
            
            
            
            // Raw data
            //total de registros por asesor
            setConteoDeRegistrosDB(OrdenarArrayPorAtributo(ContarRegistrosPorAsesor(registrosDelTrimestre), "citas", "descending"))
            //total de registros como dirección
            setConteoDeRegistrosPorSemanaDB(ContarRegistrosPorSemana(registrosDelTrimestre, fecha_a_medir))
            //total de registros por productos 
            setConteoDeProductosDB(ContarProductos(registrosDelTrimestre))
            //total de registros por productos 
            setConteoDePorcentajesDB(ContarPorcentajeDeCierres(registrosDelTrimestre))


            APIAsesoresDB
              .read("Asesores", {authentication: {
                username: process.env.REACT_APP_MIEMBROS_USERNAME,
                password: process.env.REACT_APP_MIEMBROS_PASSWORD,
              }})
              .then(data => {
                let asesoresDB = data;
                setAsesoresDB(asesoresDB)
                setLoading(false)
                setInitRender(3)
              })
              .catch(e => {
                console.error(e);
              });
  
  
  
          })
  
          .catch(e => {
            console.error(e);
          });
    }
    


    
  }, [initRender, fecha_a_medir])



  useEffect(() => {
    //vamos a cambiar a 4 unicamente cuando hayamos utilizado el select
    if(initRender === 4) {
      //reseteamos todas las variables
      setRegistrosTrimestrales([])
      setConteoDeRegistrosDB(null)
      setConteoDeRegistrosPorSemanaDB(null)
      setConteoDeProductosDB(null)
      setConteoDePorcentajesDB(null)
      
      //Estados de funcionamiento para analizar un asesor individualmente
      setAnalizarAsesor(null)
      setAnalizarAsesorRegistros([])
      setAnalizarAsesorRegistrosPorSemana([])
      setAnalizarAsesorProductos([])
      setAnalizarAsesorPorcentajes([])


      //definimos ahora los nuevos registros del trimestr seleccionado
      let registrosDelTrimestre = FiltrarRegistrosPorTrimestre(RegistrosDB, fecha_a_medir);
      setRegistrosTrimestrales(registrosDelTrimestre)
      
      // Raw data
      //total de registros por asesor
      setConteoDeRegistrosDB(OrdenarArrayPorAtributo(ContarRegistrosPorAsesor(registrosDelTrimestre), "citas", "descending"))
      //total de registros como dirección
      setConteoDeRegistrosPorSemanaDB(ContarRegistrosPorSemana(registrosDelTrimestre, fecha_a_medir))
      //total de registros por productos 
      setConteoDeProductosDB(ContarProductos(registrosDelTrimestre))
      //total de registros por productos 
      setConteoDePorcentajesDB(ContarPorcentajeDeCierres(registrosDelTrimestre))
    }

  }, [fecha_a_medir, initRender])



  // Cargando al ingresasr al dashboard, cuando carga las apis ya no
  useEffect(() => {
    if(loading) {
      Swal.fire({
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
    } else {
      Swal.close()
      // Obtener el elemento div por su ID
      const miDiv = document.getElementById('dashboard');

      // Hacer que la página se desplace automáticamente hacia el div
      miDiv.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading])




  // Busca registros por Asesor
  useEffect(() => {
    if(analizarAsesor) {
      // Obtener el elemento div por su ID
      const miDiv = document?.getElementById('searcher');
  
      // Hacer que la página se desplace automáticamente hacia el div
      miDiv?.scrollIntoView({ behavior: 'smooth' });
      
  
      const DBfiltrada = filtrarRegistrosPorAsesor(RegistrosTrimestrales, analizarAsesor.uuid);
      
      setAnalizarAsesorRegistros(DBfiltrada)
      setAnalizarAsesorRegistrosPorSemana(ContarRegistrosPorSemana(DBfiltrada, fecha_a_medir))
      setAnalizarAsesorProductos(ContarProductos(DBfiltrada))
      setAnalizarAsesorPorcentajes(ContarPorcentajeDeCierres(DBfiltrada))
    }

    
  }, [analizarAsesor])




  return(<>
    <div id="dashboard" style={{height: "40px"}} />


    {
      !loading &&
      <section className="step-wrap-container-dashboard">
        <div className="step-container">

          <SelectQuarter quarter={fecha_a_medir} setQuarter={setFecha_a_medir} setInitRender={setInitRender} />
          <div style={{ height: "20px" }} />

          <h1 className="h1" style={{ textAlign: "center" }}>
            Por Asesor - Trimestre {fecha_a_medir}
          </h1>

          <div style={{ height: "25px" }} />

          <BarChart
            grid={{vertical: true}}


            dataset={paginatedRegistros}
            

            yAxis={[{ 
              scaleType: 'band', 
              dataKey: 'name',
              valueFormatter: (v, idx) => {
                return v.split(' ')[0]; // Return only the first name
              },
            }]}


            tooltip={{
              trigger: "item",
            }}


            series={[{ 
              dataKey: 'citas', 
              label: 'Citas', 
              color: "#e89729", 
            }]}
            layout="horizontal"
            

            {...MainChartStyles}
            colors={chartsPalette}
          />

          <div className="pagination" style={{ textAlign: 'center', marginTop: '20px' }}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Anterior
            </button>
            <span style={{ margin: '0 10px' }}>Página {currentPage} de {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              Siguiente
            </button>
          </div>

        </div>
      </section>
    }
    
    
    <div id="dashboard" style={{height: "40px"}} />

    <section className="step-wrap-container step-wrap-container-dashboard">
      
      <div className="step-container">

        <h1 className="h1" style={{textAlign: "center"}}>
          Planner Expecta
        </h1>


        <div style={{height: "25px"}} />














        {
          (
            loading
          ) ?
          <div className="dashboard-container-skeleton" style={{height: "664px", display: "grid", placeItems: "center"}}>
            <h2 className="h2">Cargando Información...</h2>
          </div>
          
          :
          <div className="dashboard-container">
            <div className="chart1">
              <h3 className="h3">Mejores 5 Asesores</h3>
              <BarChart
                grid={{vertical: true}}


                dataset={ConteoDeRegistrosDB.length > 5 ? ConteoDeRegistrosDB.slice(0, 5) : ConteoDeRegistrosDB}


                yAxis={[{ 
                  scaleType: 'band', 
                  dataKey: 'name',
                  valueFormatter: (v, idx) => {
                    return v.split(' ')[0]; // Return only the first name
                  },
                }]}


                tooltip={{
                  trigger: "item",
                }}


                series={[{ 
                  dataKey: 'citas', 
                  label: 'Citas', 
                  color: "#e89729", 
                }]}
                layout="horizontal"
                

                {...TopChartSetting}
              />
            </div>












            <div className="chart2">
              <h3 className="h3">Citas {fecha_a_medir}</h3>
              <div className="lineChartContainer">
                <LineChart
                  grid={{ horizontal: true}}
                  dataset={ConteoDeRegistrosPorSemanaDB}
                  // xAxis={[{ data: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}]}
                  xAxis={[{
                    dataKey: "n_semana",
                    valueFormatter: (value => ConteoDeRegistrosPorSemanaDB[value-1].semana)
                  }]}
                  series={[
                    {
                      dataKey: "total_prospectos",
                      area: true,
                      color: "#e89729"
                    },
                  ]}
                  width={600}
                  height={300}
                />
              </div>
            </div>
            
            
            
            
            
            
            
            

            <div className="chart3">
              <h3 className="h3">Productos Registrados</h3>
              <PieChart
                colors={chartsPalette}

                series={[
                  {
                    data: ConteoDeProductosDB,
                    innerRadius: 30,
                    outerRadius: 120,
                    paddingAngle: 2,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle: 360,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  },
                ]}
                width={600}
                height={300}
              />
            </div>

            
            
            
            
            
            
            
            

            <div className="chart4">
              <h3 className="h3">Porcentaje de Cierres</h3>
              <PieChart
                colors={chartsPalette}

                series={[
                  {
                    data: ConteoDePorcentajesDB,
                    innerRadius: 30,
                    outerRadius: 120,
                    paddingAngle: 2,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle: 360,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                  },
                ]}
                width={600}
                height={300}
              />
            </div>
          </div>
        }






























      </div>
    </section>

    {
      !loading &&
      <div className="step-wrap-container-searcher">
        <h1 className="h1">Analizar asesor</h1>
        <AsesoresTable asesorData={asesorData} registros={RegistrosDB} data={AsesoresDB} onAsesorClick={setAnalizarAsesor} />
      </div>
    }

    {
      analizarAsesor &&
      <> 
        <div id="searcher" style={{height: "40px"}} />
      
        <section className="step-wrap-container step-wrap-container-dashboard">
        
          <div className="step-container">

            <div style={{height: "40px"}} />

            <h1 className="h1" style={{textAlign: "center"}}>
              Desempeño del asesor
            </h1>

            <div style={{height: "25px"}} />














            <div className="dashboard-container">
              <div className="chart1">
                <h3 className="h3">Citas registradas</h3>
                <div style={{height: "20px"}} />
                <RegistrosTable data={analizarAsesorRegistros} small />
              </div>












              <div className="chart2">
                <h3 className="h3">Historial {fecha_a_medir}</h3>
                <div className="lineChartContainer">
                  <LineChart
                    grid={{ horizontal: true}}
                    dataset={analizarAsesorRegistrosPorSemana}
                    // xAxis={[{ data: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]}]}
                    xAxis={[{
                      dataKey: "n_semana",
                        valueFormatter: (value => ConteoDeRegistrosPorSemanaDB[value-1].semana)
                    }]}
                    series={[
                      {
                        dataKey: "total_prospectos",
                        area: true,
                        color: "#e89729"
                      },
                    ]}
                    width={600}
                    height={300}
                  />
                </div>
              </div>
              
              
              
              
              
              
              
              

              <div className="chart3">
                <h3 className="h3">Productos Registrados</h3>
                <PieChart
                colors={chartsPalette}

                  series={[
                    {
                      data: analizarAsesorProductos,
                      innerRadius: 30,
                      outerRadius: 120,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: 0,
                      endAngle: 360,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}
                  width={600}
                  height={300}
                />
              </div>

              
              
              
              
              
              
              
              

              <div className="chart4">
                <h3 className="h3">Porcentaje de Cierres</h3>
                <PieChart
                colors={chartsPalette}

                  series={[
                    {
                      data: analizarAsesorPorcentajes,
                      innerRadius: 30,
                      outerRadius: 120,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: 0,
                      endAngle: 360,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}
                  width={600}
                  height={300}
                />
              </div>
            </div>






























          </div>
        </section>
      </>
    }
  </>
  )
}